import { forwardRef } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

type ActiveNavLinkProps = Omit<NavLinkProps, 'className'> & { className?: string; activateClassName?: string };

const ActiveNavLink = forwardRef<HTMLAnchorElement, ActiveNavLinkProps>(
  ({ className, activateClassName = 'active', ...props }, ref) => (
    <NavLink
      ref={ref}
      {...props}
      className={({ isActive }) => (isActive ? `${activateClassName} ${className ?? ''}`.trim() : className)}
    />
  ),
);

export default ActiveNavLink;
