import { Effect, pipe } from 'effect';
import { z, ZodError } from 'zod';

export const Email = z.string().regex(
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
  { message: "Le format d'email est incorrect" },
);
export type Email = z.infer<typeof Email>;

export const NonEmptyString = z.string().min(1);
export type NonEmptyString = z.infer<typeof NonEmptyString>;

export namespace Schema {
  export class Error {
    readonly _tag = 'SchemaError';
    readonly data: unknown;

    constructor(
      readonly error: ZodError,
      data: unknown,
    ) {}

    log = () =>
      Effect.logError(
        `[schema] failed to parse schema with data ${JSON.stringify(this.data)} : ${JSON.stringify(this.error)}`,
      );
  }

  export function parse<S extends z.ZodType>(schema: S, data: unknown): Effect.Effect<z.infer<S>, Error> {
    return pipe(
      Effect.tryPromise({
        try: () => schema.parseAsync(data),
        catch: err => new Error(err as any, data),
      }),
    );
  }

  export function parseSync<S extends z.ZodType>(schema: S, data: unknown): Effect.Effect<z.infer<S>, Error> {
    return pipe(
      Effect.try({
        try: () => schema.parse(data),
        catch: err => new Error(err as any, data),
      }),
    );
  }
}
