import { z } from 'zod';

export namespace VehicleKind {
  export const Id = z.string().uuid().brand<'VehicleKindId'>();
  export type Id = z.infer<typeof Id>;

  export const EuroviaId = z.string().uuid().brand<'VehicleKindEuroviaId'>();
  export type EuroviaId = z.infer<typeof EuroviaId>;

  export const RenterId = z.string().uuid().brand<'VehicleKindRenterId'>();
  export type RenterId = z.infer<typeof RenterId>;

  export interface EuroviaReferential {
    id: EuroviaId;
    label: string;
  }

  export interface RenterReferential {
    id: RenterId;
    label: string;
  }
}
