import { AuthService } from '@modules/auth/service';
import { defineRoute, redirect } from '@core/router';
import { defineLoader } from '@core/router/loader';
import { Effect, pipe } from 'effect';

const loader = defineLoader({
  handler: () =>
    pipe(
      AuthService.logout(),
      Effect.merge,
      Effect.flatMap(() => redirect('/login')),
    ),
});

const logoutRoute = defineRoute({
  loader,
});

export default logoutRoute;
