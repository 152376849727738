import React from 'react';
import { createLazyRoute } from '@core/router';
import { Navigate, Route } from 'react-router-dom';

const module = () => import('./module');

const importRoutes = (
  <Route path="import">
    <Route index element={<Navigate to="/import/vehicles" replace />} />

    <Route path="vehicles" {...createLazyRoute(module, m => m.vehiclesImportRoute)} />
    <Route path="lafon" {...createLazyRoute(module, m => m.lafonImportRoute)} />
  </Route>
);

export default importRoutes;
