import React, { FC } from 'react';
import AuthLayout from '@layout/auth/AuthLayout';
import LoginForm from '@modules/auth/components/LoginForm';
import { Auth } from '@modules/auth/model';
import { AuthService } from '@modules/auth/service';
import Seo from '@shared/modules/seo/Seo';
import { defineRoute, redirect } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import { Effect, Function } from 'effect';
import { QueryUtils } from '@shared/utils/queries';
import { z } from 'zod';

const queries = z.object({
  referrer: z.string().catch(() => '/'),
});

const actions = {
  authenticate: defineAction({
    type: 'authenticate',
    payload: Auth.AuthenticateParams,
    handler: ({ payload, request }) =>
      Effect.gen(function* (_) {
        yield* _(AuthService.authenticate(payload));

        const { referrer } = yield* _(Effect.orDie(QueryUtils.parseFromRequest(request, queries)));

        yield* _(redirect(referrer));
      }),
    flashOptions: {
      error: Function.constFalse,
    },
  }),
};

const LoginPage: FC = () => {
  const [loading, authenticate, error] = useAction(actions.authenticate);

  const handleAuthenticate = (params: Auth.AuthenticateParams) => Effect.runPromise(authenticate(params));

  return (
    <AuthLayout title="Bienvenue">
      <Seo title="S'identifier" />
      <LoginForm loading={loading} error={error} onSubmit={handleAuthenticate} />
    </AuthLayout>
  );
};

const loginRoute = defineRoute({
  element: <LoginPage />,
  actions,
});

export default loginRoute;
