import React from 'react';
import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

const inputRoutes = (
  <Route path="inputs">
    <Route index {...createLazyRoute(module, m => m.inputsListRoute)} />
    <Route path=":id" {...createLazyRoute(module, m => m.inputDetailRoute)} />
  </Route>
);

export const inputAwareRoutes = (
  <Route path="inputs">
    <Route path="new" {...createLazyRoute(module, m => m.inputCreateRoute)} />
  </Route>
);

export default inputRoutes;
