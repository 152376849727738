import { useMediaQuery } from '@mantine/hooks';
import { LayoutConstants } from '@layout/constants';
import { useMantineTheme } from '@mantine/core';

export function useMobileLayout(): boolean {
  const theme = useMantineTheme();

  return (
    useMediaQuery(`(max-width: ${theme.breakpoints[LayoutConstants.LAYOUT_MOBILE_BREAKPOINT]}`, undefined, {
      getInitialValueInEffect: false,
    }) ?? false
  );
}
