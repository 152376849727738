import React, { FC, PropsWithChildren } from 'react';
import { useProfilePredicate } from '@modules/profile/hooks';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import { Http } from '@core/http';
import { Profile } from '@modules/profile/model';
import { Predicate } from 'effect';
import { HttpStatusCode } from 'axios';

interface AccessGuardProps {
  predicate: Predicate.Predicate<Profile>;
}

const AccessGuard: FC<PropsWithChildren<AccessGuardProps>> = ({ predicate, children }) => {
  if (useProfilePredicate(predicate)) {
    return <>{children}</>;
  }

  return <ErrorPage error={Http.Error.fromStatusCode(HttpStatusCode.Forbidden)} />;
};

export default AccessGuard;
