import { User } from '@modules/users/model';
import { Predicate, ReadonlyArray } from 'effect';
import { Profile } from '@modules/profile/model';

export namespace ProfilePredicates {
  export const hasRole =
    (role: User.Role): Predicate.Predicate<Profile> =>
    profile =>
      profile.role === role;

  export function or(...predicates: ReadonlyArray.NonEmptyArray<Predicate.Predicate<Profile>>) {
    return Predicate.some(predicates);
  }

  export function and(...predicates: ReadonlyArray.NonEmptyArray<Predicate.Predicate<Profile>>) {
    return Predicate.every(predicates);
  }
}
