import z from 'zod';
import { Email, NonEmptyString } from '@shared/schemas';
import { Agency } from '@modules/agency/model';

export namespace User {
  export const Id = z.string().uuid().brand<'UserId'>();
  export type Id = z.infer<typeof Id>;

  export enum Role {
    AdminTech = 'ADMIN_TECH',
    AdminBusiness = 'ADMIN_BUSINESS',
    User = 'USER',
  }

  export const roleLabel: Record<Role, string> = {
    [Role.AdminTech]: 'Administrateur technique',
    [Role.AdminBusiness]: 'Administrateur métier',
    [Role.User]: 'Utilisateur',
  };

  export enum ErrorType {
    ExcelParsingError = 'excel-parsing-error',
  }

  export const errorTypeLabel: Record<ErrorType, string> = {
    [ErrorType.ExcelParsingError]: 'Erreur dans le fichier Excel',
  };

  export enum ErrorDetailType {
    EmptyRequiredCellError = 'empty-required-cell-error',
    BadCellTypeError = 'bad-cell-type-error',
    UnhandledCellType = 'unhandled-cell-type',
    AnotHandledConversion = 'anot-handled-conversion',
    ConversionError = 'conversion-error',
  }

  export interface ErrorDetail {
    type: ErrorDetailType;
    lineNumber: number;
    rowNumber: number;
  }

  export interface Error {
    cellErrors: Array<ErrorDetail>;
    type: ErrorType;
  }

  export const CreateParams = z.object({
    email: Email,
    firstName: NonEmptyString,
    lastName: NonEmptyString,
    role: z.nativeEnum(Role),
    agencyId: Agency.Id,
  });
  export type CreateParams = z.infer<typeof CreateParams>;

  export const Import = z.object({
    file: z.instanceof(File),
  });
  export type Import = z.infer<typeof Import>;

  export interface Detail {
    id: Id;
    lastName: string;
    firstName: string;
    email: string;
    agency: Agency;
    role: Role;
  }

  export interface Referential {
    id: Id;
    label: string;
  }

  export interface ReferentialDetail {
    id: Id;
    lastName: string;
    firstName: string;
    email: string;
    agency: Agency.Detail;
    role: Role;
  }
}

export interface User {
  id: User.Id;
  email: string;
  firstName: string | null;
  lastName: string | null;
  isActivated: boolean;
  role: User.Role;
  agency: Agency.Id;
}
