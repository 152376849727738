import { Option, Predicate } from 'effect';
import { Profile } from '@modules/profile/model';
import { useOptionalProfile, useProfile } from '@modules/profile/loader';

export function useProfilePredicate(predicate: Predicate.Predicate<Profile>): boolean {
  return predicate(useProfile());
}

export function useOptionalProfilePredicate(predicate: Predicate.Predicate<Profile>): boolean {
  return Option.exists(useOptionalProfile(), predicate);
}
