import { Http } from '@core/http/model';
import config from '@root/config';

export function getApiBaseURL(prefix: string): string {
  if (import.meta.env.DEV) {
    return `/api${prefix}`;
  } else {
    return new URL(prefix, config.VITE_API_URL).toString();
  }
}

export function isHttpError(error: unknown): error is Http.Error {
  return (error as any)._tag === 'HttpError';
}
