import { defineLoader } from '@core/router/loader';
import { z } from 'zod';
import { Auth } from '@modules/auth/model';
import { AuthService } from '@modules/auth/service';
import { defineRoute, redirect } from '@core/router';
import { Effect, pipe } from 'effect';

const params = z.object({ token: Auth.Token });

const loader = defineLoader({
  params,
  handler: ({ params }) =>
    pipe(
      AuthService.exchangeToken(params.token),
      Effect.merge,
      Effect.flatMap(() => redirect('/')),
    ),
});

const loginWithTokenRoute = defineRoute({
  loader,
});

export default loginWithTokenRoute;
