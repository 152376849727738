import React, { FC } from 'react';

import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Auth } from '@modules/auth/model';
import { renderOptional } from '@shared/utils/render';
import { Alert, Anchor, Button, Group, PasswordInput, Text, TextInput } from '@mantine/core';
import { OAuth } from '@core/oauth/model';
import { OAuthUtils } from '@core/oauth/utils';
import { Option } from 'effect';

interface LoginFormProps {
  loading: boolean;
  error: Option.Option<OAuth.HttpError>;
  onSubmit: (params: Auth.AuthenticateParams) => void;
}

const LoginForm: FC<LoginFormProps> = ({ loading, error, onSubmit }) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<Auth.AuthenticateParams>({
    resolver: zodResolver(Auth.AuthenticateParams),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {renderOptional(error, {
        onSome: error => (
          <Alert color="red" mb={10}>
            <Text c="red" size="sm" fw={600} ta="center">
              {OAuthUtils.getOAuthErrorCode(error) === OAuth.ErrorCode.InvalidGrant
                ? 'Email ou mot de passe incorrect.'
                : 'Une erreur technique est survenue.'}
            </Text>
          </Alert>
        ),
      })}

      <TextInput
        id="email"
        type="email"
        label="E-mail"
        required
        placeholder="Saisir votre e-mail"
        error={!!errors.email}
        {...register('email')}
      />

      <PasswordInput
        mt="sm"
        id="password"
        label="Mot de passe"
        required
        placeholder="Saisir votre mot de passe"
        error={!!errors.password}
        {...register('password')}
      />

      <Group mt={5} justify="right">
        <Anchor component={Link} c="dark.3" underline="always" to="/password-reset/forgot" size="xs" fw="600">
          Mot de passe oublié ?
        </Anchor>
      </Group>

      <Group mt="xl" justify="center" grow>
        <Button type="submit" loading={loading} disabled={loading}>
          Se connecter
        </Button>
      </Group>
    </form>
  );
};

export default LoginForm;
