import { filterEmptyStringToOption } from '@shared/utils/string';
import { User } from '@modules/users/model';
import { pipe, Option, ReadonlyArray, String } from 'effect';

export namespace UsersUtils {
  type UserNames = Pick<User, 'email' | 'firstName' | 'lastName'>;

  export function getUserName({ email, firstName, lastName }: UserNames) {
    return pipe(
      Option.all(ReadonlyArray.make(filterEmptyStringToOption(firstName), filterEmptyStringToOption(lastName))),
      Option.match({
        onSome: ReadonlyArray.join(' '),
        onNone: () => email,
      }),
    );
  }

  export function getUserInitial({ email, firstName, lastName }: UserNames) {
    return pipe(
      Option.all({
        firstname: filterEmptyStringToOption(firstName),
        lastname: filterEmptyStringToOption(lastName),
      }),
      Option.match({
        onSome: ({ firstname, lastname }) => `${firstname.charAt(0)}${lastname.charAt(0)}`,
        onNone: () => email.charAt(0),
      }),
      String.toUpperCase,
    );
  }

  export function mapUserToParams(user: User.Detail): User.CreateParams {
    return {
      email: user.email,
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      agencyId: user.agency.id,
      role: user.role,
    };
  }
}
