import { z } from 'zod';
import { VehicleKind } from '@modules/vehicle_kinds/model';

export namespace Agency {
  import EuroviaId = VehicleKind.EuroviaId;
  export const Id = z.string().uuid().brand<'AgencyId'>();
  export type Id = z.infer<typeof Id>;

  export interface Referential {
    id: Id;
    label: string;
  }

  export interface Detail {
    id: Id;
    label: string;
    euroviaId: EuroviaId;
    region: string;
  }
}

export interface Agency {
  id: Agency.Id;
  label: string;
  lafonLabel: string;
  region: string;
}
