import React, { ComponentType, FC, lazy, ReactNode, Suspense } from 'react';
import {
  ActionFunction,
  LoaderFunction,
  Outlet,
  Params,
  redirect as routerRedirect,
  resolvePath,
  ShouldRevalidateFunction,
  To,
  useParams,
} from 'react-router-dom';
import { FunctionalRouteExport, RouteExport } from '@core/router/model';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import { actionHandler, Actions } from '@core/router/action';
import z from 'zod';
import { Loader, loaderHandler } from '@core/router/loader';
import { Effect, pipe, String } from 'effect';
import { createPath } from 'history';
import { Schema } from '@shared/schemas';
import AccessGuard from '@core/router/components/AccessGuard';

export function safeLazy<T extends ComponentType<any>>(factory: () => Promise<{ default: T }>) {
  return lazy<T>(() =>
    factory().catch(() => {
      window.location.reload();

      return new Promise(resolve => {
        setTimeout(resolve, 3000);
      });
    }),
  );
}

export function defineRoute<L extends Loader<any, any, any>, A extends Actions, RE extends RouteExport<L, A>>(
  route: RE,
): RE {
  return route;
}

export interface CreateRouteReturn {
  id?: string;
  element: ReactNode;
  loader?: LoaderFunction;
  action?: ActionFunction;
  errorElement?: ReactNode;
  shouldRevalidate: ShouldRevalidateFunction;
}

export function createRoute<L extends Loader<any, any, any>, A extends Actions, RE extends RouteExport<L, A>>(
  routeExport: RE,
): CreateRouteReturn;
export function createRoute<
  P,
  L extends Loader<any, any, any>,
  A extends Actions,
  RE extends FunctionalRouteExport<P, L, A>,
>(routeExport: RE, props: P): CreateRouteReturn;

export function createRoute(
  routeExport: RouteExport<any, any> | FunctionalRouteExport<any, any, any>,
  props?: any,
): CreateRouteReturn {
  const { actions, loader, element, restriction, shouldRevalidate } =
    typeof routeExport === 'function' ? routeExport(props) : routeExport;

  const getElement = () => {
    const children = element ? <Suspense>{element}</Suspense> : <Outlet />;

    if (restriction) {
      return <AccessGuard predicate={restriction}>{children}</AccessGuard>;
    }

    return children;
  };

  const defaultShouldRevalidate: ShouldRevalidateFunction = ({ formMethod, currentUrl, nextUrl }) => {
    return (
      formMethod?.toUpperCase() === 'POST' ||
      currentUrl.pathname + currentUrl.search !== nextUrl.pathname + nextUrl.search
    );
  };

  return {
    id: loader ? loader.id : undefined,
    element: getElement(),
    action: actions ? actionHandler(actions) : undefined,
    loader: loaderHandler(loader),
    errorElement: <ErrorPage />,
    shouldRevalidate: shouldRevalidate || defaultShouldRevalidate,
  };
}

export interface CreateLazyRouteReturn {
  id?: CreateRouteReturn['id'];
  lazy: () => Promise<Omit<CreateRouteReturn, 'id'>>;
}

export function createLazyRoute<
  M,
  ID extends string,
  L extends Loader<any, any, ID>,
  A extends Actions,
  RE extends RouteExport<L, A>,
>(module: () => Promise<M>, mapper: (module: M) => RE, id: ID): CreateLazyRouteReturn;
export function createLazyRoute<M, L extends Loader<any, any>, A extends Actions, RE extends RouteExport<L, A>>(
  module: () => Promise<M>,
  mapper: (module: M) => RE,
): CreateLazyRouteReturn;
export function createLazyRoute<
  M,
  ID extends string,
  P,
  L extends Loader<any, any, ID>,
  A extends Actions,
  RE extends FunctionalRouteExport<P, L, A>,
>(module: () => Promise<M>, mapper: (module: M) => RE, id: ID, props: P): CreateLazyRouteReturn;
export function createLazyRoute<
  M,
  P,
  L extends Loader<any, any>,
  A extends Actions,
  RE extends FunctionalRouteExport<P, L, A>,
>(module: () => Promise<M>, mapper: (module: M) => RE, id: undefined, props: P): CreateLazyRouteReturn;

export function createLazyRoute<M>(
  module: () => Promise<M>,
  mapper: (module: M) => RouteExport<any, any> | FunctionalRouteExport<any, any, any>,
  id?: string,
  props?: any,
): CreateLazyRouteReturn {
  return {
    id,
    lazy: () =>
      module()
        .then(m => {
          const { id, ...route } = createRoute(mapper(m) as any, props);

          return route;
        })
        .catch(e => {
          console.error(e);
          window.location.reload();

          return new Promise(resolve => {
            setTimeout(resolve, 3000);
          });
        }),
  };
}

export function parseParams<ParamsSchema extends z.ZodType = z.ZodType<unknown>>(
  params: Params,
  paramsSchema?: z.ZodType,
): Effect.Effect<z.infer<ParamsSchema>> {
  if (paramsSchema) {
    return pipe(
      Schema.parse(paramsSchema, params),
      Effect.orDieWith(() => new Response('[loader] failed to parse params', { status: 404 })),
    );
  }

  return Effect.succeed(params);
}

export function preventActionLeave<A extends Actions>(
  type: A[keyof A]['type'] | Array<A[keyof A]['type']>,
): ShouldRevalidateFunction {
  const types: Array<string> = Array.isArray(type) ? type : [type];
  return ({ formData, defaultShouldRevalidate }) => {
    const _type = formData?.get('_type');

    if (_type && String.isString(_type)) return defaultShouldRevalidate && !types.includes(_type);

    return defaultShouldRevalidate;
  };
}

export function withKeyObserver<
  L extends Loader<any, any, any>,
  Props = {},
  Params extends Record<string, string> = L extends Loader<infer ParamsSchema, any, any>
    ? z.infer<ParamsSchema>
    : never,
>(Component: FC<Props>, key: keyof Params): FC<Props> {
  return (props: Props) => {
    const params = useParams<Params>();

    return <Component key={params[key]} {...props} />;
  };
}

export function throwResponse(response: Response) {
  return Effect.die(response);
}

export function redirect(to: To) {
  return throwResponse(routerRedirect(createPath(resolvePath(to, window.location.pathname))));
}
