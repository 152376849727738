import React, { FC, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  useNavigation,
} from 'react-router-dom';

import { createRoute } from '@core/router';
import Layout from '@layout/Layout';

import { resetNavigationProgress, startNavigationProgress } from '@mantine/nprogress';
import ErrorPage from '@shared/components/error-page/ErrorPage';

import profileRoute from '@modules/profile/routes';
import usersRoutes from '@modules/users/routes';
import importRoutes from '@modules/import/routes';
import retailersRoutes from '@modules/retailers/routes';
import rentersRoutes from '@modules/renters/routes';
import qrRoutes from '@modules/qr/routes';
import logoutRoute from '@modules/auth/logout';
import passwordsRoutes from '@modules/passwords/routes';
import authRoutes from '@modules/auth/routes';
import profileLoaderRoute, { optionalProfileLoaderRoute } from '@modules/profile/loader';
import scanRoutes from '@modules/scan/routes';
import inputRoutes, { inputAwareRoutes } from '@modules/inputs/routes';

const NavigationLoader: FC = () => {
  const navigation = useNavigation();

  useEffect(() => {
    if (navigation.state !== 'idle') {
      startNavigationProgress();
    } else {
      resetNavigationProgress();
    }
  }, [navigation.state]);

  return <Outlet />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<NavigationLoader />}>
      {authRoutes}
      {passwordsRoutes}

      <Route {...createRoute(profileLoaderRoute)}>
        <Route element={<Layout />}>
          <Route index element={<Navigate to="/inputs" replace />} />

          {profileRoute}
          {inputRoutes}
          {usersRoutes}
          {importRoutes}
          {retailersRoutes}
          {rentersRoutes}
          {qrRoutes}

          <Route path="/logout" {...createRoute(logoutRoute)} />

          <Route path="*" element={<ErrorPage />} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Route>

      <Route {...createRoute(optionalProfileLoaderRoute)}>
        <Route element={<Layout />}>{inputAwareRoutes}</Route>
      </Route>

      {scanRoutes}
    </Route>,
  ),
);

export default router;
