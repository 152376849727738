import { OAuth } from '@core/oauth/model';
import { HttpStatusCode } from 'axios';

export namespace OAuthUtils {
  export function getOAuthErrorCode(error: OAuth.HttpError): OAuth.ErrorCode | null {
    if (error.status === HttpStatusCode.BadRequest) {
      return Object.values(OAuth.ErrorCode).find(code => code === error.data?.error) ?? null;
    }

    return null;
  }
}
