import { Http } from '@core/http';
import { z } from 'zod';

export namespace OAuth {
  export const AccessToken = z.string().brand<'AccessToken'>();
  export type AccessToken = z.infer<typeof AccessToken>;

  export const RefreshToken = z.string().brand<'RefreshToken'>();
  export type RefreshToken = z.infer<typeof RefreshToken>;

  export interface Tokens {
    access_token: AccessToken;
    expires_in: number | null;
    refresh_token: RefreshToken | null;
    token_type: string;
  }

  export type SavedTokens = Pick<Tokens, 'access_token' | 'refresh_token'>;

  export interface PasswordRequest {
    grant_type: 'password';
    username: string;
    password: string;
  }

  export interface RefreshTokensRequest {
    grant_type: 'refresh_token';
    refresh_token: string;
  }

  export type Request = PasswordRequest | RefreshTokensRequest;

  export enum ErrorCode {
    InvalidRequest = 'invalid_request',
    InvalidClient = 'invalid_client',
    InvalidGrant = 'invalid_grant',
    UnauthorizedClient = 'unauthorized_client',
    UnsupportedGrantType = 'unsupported_grant_type',
  }

  export interface Error {
    error: ErrorCode;
  }

  export type HttpError = Http.Error<Error>;
  export type HttpEffect = Http.Effect<void, Error>;
}
