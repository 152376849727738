import React, { FC, useEffect, useMemo } from 'react';
import { Http } from '@core/http';

import { isRouteErrorResponse, Link, useAsyncError, useRouteError } from 'react-router-dom';
import { Button, Text, Title } from '@mantine/core';
import * as Sentry from '@sentry/react';
import { HttpStatusCode } from 'axios';
import classes from './ErrorPage.module.css';

interface ErrorPageProps {
  error?: Http.Error;
}

const ErrorPage: FC<ErrorPageProps> = ({ error: defaultError = Http.Error.notFound }) => {
  const internalError = useRouteError();
  const deferredError = useAsyncError();

  const error = internalError ?? deferredError;

  useEffect(() => {
    if (error) {
      if (isRouteErrorResponse(error) && error.status !== 404) {
        Sentry.captureException(error, {
          level: 'error',
        });
      } else {
        Sentry.captureException(error, {
          level: 'error',
        });
      }
    }
  }, [error]);

  const title = useMemo(() => {
    const getTitleFromStatus = (status: number) => {
      switch (status) {
        case HttpStatusCode.NotFound:
          return 'Page introuvable';
        case HttpStatusCode.Forbidden:
          return `Vous n'êtes pas autorisé à accéder à cette page`;
        default:
          return `Erreur ${status}`;
      }
    };

    if (error) {
      if (isRouteErrorResponse(error)) {
        return getTitleFromStatus(error.status);
      }

      return 'Erreur technique interne';
    }

    return getTitleFromStatus(defaultError.status);
  }, [defaultError.status, error]);

  return (
    <div className={classes.container}>
      <Title>{title}</Title>

      <Text p="30px 0">Nous vous invitons à revenir à l'accueil</Text>

      <Button component={Link} to="/">
        Revenir à l'accueil
      </Button>
    </div>
  );
};

export default ErrorPage;
