import { createTheme, InputWrapper, MantineProvider, Modal, Paper, rem } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import React, { FC, PropsWithChildren } from 'react';

const theme = createTheme({
  fontFamily: 'Open Sans, sans-serif',
  colors: {
    blue: [
      '#eef3fc',
      '#d9e3f3',
      '#afc5e9',
      '#83a5e0',
      '#5f8ad8',
      '#4879d4',
      '#3c70d4',
      '#2f5fbb',
      '#2754a7',
      '#194994',
    ],
  },
  headings: {
    sizes: {
      h1: {
        fontSize: rem(20),
      },
    },
  },
  components: {
    InputWrapper: InputWrapper.extend({
      styles: {
        label: {
          fontWeight: 600,
          paddingBottom: 5,
        },
      },
    }),
    Paper: Paper.extend({
      defaultProps: {
        withBorder: true,
      },
    }),
    Modal: Modal.extend({
      styles: {
        root: {
          zIndex: 2000,
        },
      },
      defaultProps: {
        overlayProps: {
          color: 'gray.2',
          blur: 2,
        },
      },
    }),
  },
});

const MantineTheme: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MantineProvider theme={theme}>
      <Notifications position="top-right" />
      {children}
    </MantineProvider>
  );
};

export default MantineTheme;
