import { z } from 'zod';
import { Email, NonEmptyString } from '@shared/schemas';

export namespace Auth {
  export const Token = z.string().brand('AuthToken');
  export type Token = z.infer<typeof Token>;

  export const AuthenticateParams = z.object({
    email: Email,
    password: NonEmptyString,
  });

  export type AuthenticateParams = z.infer<typeof AuthenticateParams>;
}
