import { Auth } from '@modules/auth/model';
import { OAuthService } from '@core/oauth/service';
import { OAuth } from '@core/oauth/model';
import { Effect, pipe } from 'effect';
import { HttpService } from '@core/http';

export namespace AuthService {
  export function authenticate({ email, password }: Auth.AuthenticateParams): OAuth.HttpEffect {
    return OAuthService.passwordStrategyRequest(email, password);
  }

  export function exchangeToken(token: Auth.Token): OAuth.HttpEffect {
    return pipe(
      HttpService.post<OAuth.Tokens, OAuth.Error>('/authenticate/as/exchange', {
        access_token: token,
      }),
      Effect.flatMap(OAuthService.saveOAuthTokensInStorage),
      Effect.asUnit,
    );
  }

  export function logout() {
    return OAuthService.removeOAuthTokensInStorage();
  }
}
