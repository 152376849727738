import Nav from '@layout/nav/Nav';
import React, { FC, Suspense, useCallback, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { DebouncedLineLoader } from './loaders/line-loader/LineLoader';
import { ActionIcon, AppShell } from '@mantine/core';
import { useOptionalProfile } from '@modules/profile/loader';
import { Option } from 'effect';

import classes from './Layout.module.css';
import { renderOptional } from '@shared/utils/render';
import { useClickOutside, useLocalStorage } from '@mantine/hooks';
import { LayoutConstants } from '@layout/constants';
import { IconArrowBarToRight } from '@tabler/icons-react';
import { useMobileLayout } from '@shared/utils/styles';

const Layout: FC = () => {
  const profile = useOptionalProfile();

  const isMobileLayout = useMobileLayout();

  const isMobileLayoutRef = useRef(isMobileLayout);
  isMobileLayoutRef.current = isMobileLayout;

  const [navOpen, setNavOpen] = useLocalStorage({
    key: 'nav-open',
    defaultValue: !isMobileLayout,
    getInitialValueInEffect: false,
  });

  const navRef = useRef<HTMLElement | null>(null);
  const [mobileToggleNavRef, setMobileToggleNavRef] = useState<HTMLElement | null>(null);

  const closeNavOnMobile = useCallback(() => {
    if (isMobileLayoutRef.current) {
      setNavOpen(false);
    }
  }, [setNavOpen]);

  useClickOutside(closeNavOnMobile, null, [mobileToggleNavRef, navRef.current]);

  const isAuthenticated = Option.isSome(profile);

  const handleToggleNav = () => setNavOpen(old => !old);

  return (
    <AppShell
      navbar={
        isAuthenticated
          ? navOpen || !isMobileLayout
            ? { width: 270, breakpoint: 'xs' }
            : { width: 0, breakpoint: 'sm' }
          : undefined
      }
      classNames={{ main: classes.main }}
    >
      {renderOptional(profile, {
        onSome: profile => (
          <Nav
            profile={profile}
            open={navOpen}
            onToggleNav={handleToggleNav}
            isMobileLayout={isMobileLayout ?? false}
          />
        ),
      })}

      <ActionIcon
        size={34}
        ref={setMobileToggleNavRef}
        variant="outline"
        bg="white"
        radius="xl"
        onClick={handleToggleNav}
        style={{
          display: isMobileLayout ? 'inherit' : 'none',
          left: navOpen ? LayoutConstants.NAV_OPEN_WIDTH - 16 : 20,
          transform: `rotate(${navOpen ? '180deg' : '0'})`,
          zIndex: 1000,
        }}
        classNames={{
          root: classes.buttonRoot,
        }}
      >
        <IconArrowBarToRight size={18} />
      </ActionIcon>

      <AppShell.Main>
        <Suspense fallback={<DebouncedLineLoader />}>
          <Outlet />
        </Suspense>
      </AppShell.Main>
    </AppShell>
  );
};

export default Layout;
