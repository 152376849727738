import queryString, { ParsedQuery, ParseOptions, StringifiableRecord } from 'query-string';
import { camelToSnake, snakeToCamel } from '@shared/utils/string';
import { StringifyOptions } from 'query-string/base';
import { Effect, pipe, ReadonlyArray, ReadonlyRecord } from 'effect';
import { z } from 'zod';
import { Schema } from '../schemas';

export namespace QueryUtils {
  function transformQueriesKey<T extends ParsedQuery | StringifiableRecord>(
    transform: (key: string) => string,
  ): (queries: T) => T {
    return queries =>
      pipe(
        queries,
        ReadonlyRecord.toEntries,
        ReadonlyArray.map(([key, value]) => [transform(key), value] as const),
        ReadonlyRecord.fromEntries,
      ) as T;
  }

  export const queryToSnakeCase = transformQueriesKey<StringifiableRecord>(camelToSnake);
  export const queryToCamelCase = transformQueriesKey<ParsedQuery>(snakeToCamel);

  const stringifyOptions: StringifyOptions = {
    skipEmptyString: true,
    skipNull: true,
    arrayFormat: 'none',
  };

  export function stringify(queries: StringifiableRecord): string {
    return queryString.stringify(queryToSnakeCase(queries), stringifyOptions);
  }

  const parseOptions: ParseOptions = {
    arrayFormat: 'none',
  };

  export function parse(queries: string): Effect.Effect<ParsedQuery>;
  export function parse<S extends z.ZodType>(queries: string, schema: S): Effect.Effect<z.infer<S>, Schema.Error>;

  export function parse<S extends z.ZodType>(queries: string, schema?: S) {
    return Effect.gen(function* (_) {
      const query = yield* _(Effect.sync(() => queryToCamelCase(queryString.parse(queries, parseOptions))));

      if (schema) {
        return yield* _(Schema.parse(schema, query));
      }

      return query;
    });
  }

  export function parseFromUrl(url: string): Effect.Effect<ParsedQuery>;
  export function parseFromUrl<S extends z.ZodType>(url: string, schema: S): Effect.Effect<z.infer<S>, Schema.Error>;

  export function parseFromUrl<S extends z.ZodType>(url: string, schema?: S) {
    return Effect.gen(function* (_) {
      const query = yield* _(Effect.sync(() => queryToCamelCase(queryString.parseUrl(url, parseOptions).query)));

      if (schema) {
        return yield* _(Schema.parse(schema, query));
      }

      return query;
    });
  }

  export function parseFromRequest(request: Request): Effect.Effect<ParsedQuery>;
  export function parseFromRequest<S extends z.ZodType>(
    request: Request,
    schema: S,
  ): Effect.Effect<z.infer<S>, Schema.Error>;

  export function parseFromRequest<S extends z.ZodType>(request: Request, schema?: S) {
    if (schema) {
      return parseFromUrl(request.url, schema);
    }

    return parseFromUrl(request.url);
  }

  export const BooleanQuerySchema = z.union([z.boolean(), z.string().transform(value => value === 'true')]);
}
