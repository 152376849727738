import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

const qrRoutes = (
  <Route path="qr">
    <Route index element={<Navigate to="/qr/vans" replace />} />

    <Route path="vans" {...createLazyRoute(module, m => m.vansQRCodeListRoute)} />
    <Route path="agencies" {...createLazyRoute(module, m => m.agenciesQRCodeListRoute)} />
    <Route path="users" {...createLazyRoute(module, m => m.usersQRCodeListRoute)} />
    <Route path="eurovia" {...createLazyRoute(module, m => m.vehiclesEuroviaQRCodeListRoute)} />
    <Route path="renters-kinds" {...createLazyRoute(module, m => m.vehiclesRenterKindsListRoute)} />

    <Route path="renters" {...createLazyRoute(module, m => m.rentersLayoutRoute)}>
      <Route path=":id" {...createLazyRoute(module, m => m.rentersAgencyQRCodeListRoute)} />
    </Route>
  </Route>
);

export default qrRoutes;
