import { defineLoader, useParentLoader } from '@core/router/loader';

import { defineRoute, redirect } from '@core/router';
import { ProfileService } from '@modules/profile/service';
import { Effect, pipe, Option, ReadonlyArray } from 'effect';
import { HttpStatusCode } from 'axios';
import { createPath } from 'history';
import { resolvePath, useMatches } from 'react-router-dom';
import { AuthUtils } from '@modules/auth/utils';
import { Profile } from '@modules/profile/model';

const LOADER_ID = 'profile-loader';
const OPTIONAL_LOADER_ID = 'optional-profile-loader';

function fetchProfile<S, F>(onSuccess: (profile: Profile) => Effect.Effect<S>, onFailure: () => Effect.Effect<F>) {
  return pipe(
    ProfileService.getProfile(),
    Effect.matchEffect({
      onSuccess: onSuccess,
      onFailure: err => {
        if (HttpStatusCode.Unauthorized === err.status) {
          return onFailure();
        }

        return Effect.fail(err);
      },
    }),
  );
}

const profileLoader = defineLoader({
  id: LOADER_ID,
  handler: () => fetchProfile(Effect.succeed, () => redirect(createPath(resolvePath(AuthUtils.createLoginLink(true))))),
});

const optionalProfileLoader = defineLoader({
  id: OPTIONAL_LOADER_ID,
  handler: () =>
    fetchProfile(
      profile => Effect.succeed(Option.some(profile)),
      () => Effect.succeed(Option.none<Profile>()),
    ),
});

const profileLoaderRoute = defineRoute({
  loader: profileLoader,
});

export const optionalProfileLoaderRoute = defineRoute({
  loader: optionalProfileLoader,
});

export function useProfile() {
  return useParentLoader<typeof profileLoaderRoute.loader>(LOADER_ID);
}

export function useOptionalProfile() {
  const matches = useMatches();

  const findOptionalProfile = () =>
    pipe(
      matches,
      ReadonlyArray.findFirst(match => match.id === OPTIONAL_LOADER_ID),
      Option.map(match => match.data as Option.Option<Profile>),
    );

  const findProfile = () =>
    pipe(
      matches,
      ReadonlyArray.findFirst(match => match.id === LOADER_ID),
      Option.map(match => Option.some(match.data as Profile)),
    );

  return pipe(findOptionalProfile(), Option.orElse(findProfile), Option.flatten);
}

export default profileLoaderRoute;
