import { OAuth } from '@core/oauth/model';
import { getApiBaseURL, Http, HttpService } from '@core/http';

import localforage from 'localforage';
import config from '@root/config';
import queryString from 'query-string';
import { Effect, Option, pipe } from 'effect';
import { HttpStatusCode } from 'axios';
import { EffectUniq } from '@core/fp';

const tokensStorage = localforage.createInstance({
  name: 'eurovia',
  storeName: 'oauth',
});

const TOKENS_KEY = 'tokens';

export namespace OAuthService {
  const uniqRefreshToken = new EffectUniq<void, OAuth.HttpError>();

  export function getOAuthTokensFromStorage() {
    return pipe(
      Effect.promise(() => tokensStorage.getItem<OAuth.SavedTokens>(TOKENS_KEY)),
      Effect.map(Option.fromNullable),
    );
  }

  export function saveOAuthTokensInStorage({ refresh_token, access_token }: OAuth.Tokens) {
    return Effect.promise(() => tokensStorage.setItem<OAuth.SavedTokens>(TOKENS_KEY, { refresh_token, access_token }));
  }

  export function removeOAuthTokensInStorage() {
    return Effect.promise(() => tokensStorage.removeItem(TOKENS_KEY));
  }

  function sendOAuthRequest(request: OAuth.Request): OAuth.HttpEffect {
    return pipe(
      HttpService.post<OAuth.Tokens, OAuth.Error>('/tokens', queryString.stringify(request), {
        baseURL: getApiBaseURL(config.VITE_API_OAUTH_PREFIX),
      }),
      Effect.tap(saveOAuthTokensInStorage),
      Effect.asUnit,
    );
  }

  export function passwordStrategyRequest(email: string, password: string): OAuth.HttpEffect {
    return sendOAuthRequest({ grant_type: 'password', username: email, password });
  }

  export function refreshToken(): OAuth.HttpEffect {
    const task = pipe(
      getOAuthTokensFromStorage(),
      Effect.flatten,
      Effect.flatMap(({ refresh_token }) => Option.fromNullable(refresh_token)),
      Effect.orElse(() =>
        new Http.Error(HttpStatusCode.NotFound, 'No refresh token in storage', 'none', 'none', {
          error: OAuth.ErrorCode.InvalidGrant,
        }).fail(),
      ),
      Effect.flatMap(refresh_token => sendOAuthRequest({ grant_type: 'refresh_token', refresh_token })),
      Effect.tapError(removeOAuthTokensInStorage),
    );

    return uniqRefreshToken.lock(task);
  }
}
