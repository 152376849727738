import logo from '@assets/logos/logo.svg';
import React, { forwardRef, ReactNode, useMemo } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ActionIcon, Anchor, AppShell, Avatar, rgba, Text, useMantineTheme } from '@mantine/core';
import { IconCloudDownload, IconEdit, IconLogout, IconQrcode, IconUsersGroup, IconWand } from '@tabler/icons-react';
import { renderNullable } from '@shared/utils/render';
import { UsersUtils } from '@modules/users/utils';
import { Profile } from '@modules/profile/model';
import classes from './Nav.module.css';
import classNames from 'classnames';
import { Predicate, Function, pipe, ReadonlyArray, Option } from 'effect';
import { User } from '@modules/users/model';
import { ProfilePredicates } from '@modules/profile/predicates';

interface NavEntry {
  title: string;
  to: string;
  icon: ReactNode;
  canAccess: Predicate.Predicate<Profile>;
}

interface NavGroup {
  title?: string;
  entries: ReadonlyArray.NonEmptyArray<NavEntry>;
}

const entries: ReadonlyArray.NonEmptyArray<NavGroup> = [
  {
    title: 'Menu',
    entries: [
      {
        title: 'Saisies',
        to: '/inputs',
        icon: <IconEdit />,
        canAccess: Function.constTrue,
      },
      {
        title: 'Utilisateurs',
        to: '/users',
        icon: <IconUsersGroup />,
        canAccess: ProfilePredicates.or(
          ProfilePredicates.hasRole(User.Role.AdminTech),
          ProfilePredicates.hasRole(User.Role.AdminBusiness),
        ),
      },
      {
        title: 'Import',
        to: '/import',
        icon: <IconCloudDownload />,
        canAccess: ProfilePredicates.hasRole(User.Role.AdminTech),
      },
      {
        title: 'Référentiels',
        to: '/retailers',
        icon: <IconWand />,
        canAccess: ProfilePredicates.hasRole(User.Role.AdminTech),
      },
      {
        title: 'QR Codes',
        to: '/qr',
        icon: <IconQrcode />,
        canAccess: ProfilePredicates.hasRole(User.Role.AdminTech),
      },
    ],
  },
];

interface NavProps {
  profile: Profile;
  open: boolean;
  onToggleNav: () => void;
  isMobileLayout: boolean;
}

const Nav = forwardRef<HTMLElement, NavProps>(({ profile, open, onToggleNav, isMobileLayout }, ref) => {
  const theme = useMantineTheme();

  const filteredEntries = useMemo(
    () =>
      pipe(
        entries,
        ReadonlyArray.filterMap(group =>
          pipe(
            group.entries,
            ReadonlyArray.filter(entry => entry.canAccess(profile)),
            ReadonlyArray.match({
              onNonEmpty: entries => Option.some({ ...group, entries }),
              onEmpty: Option.none,
            }),
          ),
        ),
      ),
    [profile],
  );

  return open || !isMobileLayout ? (
    <>
      <AppShell.Navbar style={{ width: 270 }}>
        <AppShell.Section grow>
          <div className={classes.content} style={{ '--shape-bg': rgba(theme.colors.blue[1], 0.2) }}>
            <div className={classes.header} style={{ '--border-color': rgba(theme.colors.blue[1], 0.5) }}>
              <Link className={classes.logo} to="/">
                <img src={logo} alt="logo" width={100} height={66} />
              </Link>
            </div>

            {filteredEntries.map((group, i) => (
              <div key={i} className={classes.group}>
                {renderNullable(group.title, {
                  onSome: title => (
                    <Text fw={600} px={10} mb={10}>
                      {title}
                    </Text>
                  ),
                })}

                <ul className={classes.entries}>
                  {group.entries.map((entry, j) => (
                    <li key={j}>
                      <NavLink
                        className={({ isActive }) => classNames(classes.item, { [classes.active]: isActive })}
                        to={entry.to}
                        onClick={onToggleNav}
                      >
                        <div className={classes.icon}>{entry.icon}</div>

                        <Text fz="sm">{entry.title}</Text>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </AppShell.Section>

        <AppShell.Section>
          <div className={classes.footer}>
            <Avatar size="sm" radius="xl" color="dark" variant="filled">
              {UsersUtils.getUserInitial(profile)}
            </Avatar>

            <Anchor component={Link} onClick={onToggleNav} to="/profile" fw={600} c="dark" fz="sm">
              {UsersUtils.getUserName(profile)}
            </Anchor>

            <ActionIcon component={Link} to="/logout" size="sm" color="blue">
              <IconLogout size="14" />
            </ActionIcon>
          </div>
        </AppShell.Section>
      </AppShell.Navbar>
    </>
  ) : null;
});

export default Nav;
