import React from 'react';
import { createLazyRoute } from '@core/router';
import { Route } from 'react-router-dom';

const module = () => import('./module');

const profileRoutes = (
  <Route path="profile">
    <Route index {...createLazyRoute(module, m => m.profileRoute)} />
  </Route>
);

export default profileRoutes;
