import { Effect, Fiber, Option, pipe, Ref } from 'effect';

export class EffectUniq<A = unknown, E = unknown> {
  private fork = Ref.unsafeMake<Option.Option<Fiber.RuntimeFiber<A, E>>>(Option.none());

  lock = (effect: Effect.Effect<A, E>): Effect.Effect<A, E> => {
    return pipe(
      Ref.get(this.fork),
      Effect.flatMap(
        Option.match({
          onSome: Effect.fromFiber,
          onNone: () =>
            pipe(
              Effect.fork(effect),
              Effect.tap(fork => Ref.set(this.fork, Option.some(fork))),
              Effect.flatMap(fork => Effect.fromFiber(fork)),
              Effect.onExit(() => Ref.set(this.fork, Option.none())),
            ),
        }),
      ),
    );
  };
}
