import React from 'react';

import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

const passwordsRoutes = (
  <Route>
    <Route path="password-reset">
      <Route path="forgot" {...createLazyRoute(module, m => m.forgotPasswordRoute)} />
      <Route path=":token" {...createLazyRoute(module, m => m.resetPasswordRoute)} />
    </Route>

    <Route path="activation/:token" {...createLazyRoute(module, m => m.activationRoute)} />
  </Route>
);

export default passwordsRoutes;
