import { Navigate, Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

const retailersRoutes = (
  <Route path="retailers">
    <Route index element={<Navigate to="/retailers/stations" replace />} />

    <Route path="stations">
      <Route index {...createLazyRoute(module, m => m.retailersStationsListRoute)} />
      <Route path=":id" {...createLazyRoute(module, m => m.retailersStationsDetailRoute)} />
      <Route path="new" {...createLazyRoute(module, m => m.createRetailersStationsRoute)} />
    </Route>

    <Route path="bordabords">
      <Route index {...createLazyRoute(module, m => m.retailersBordabordsListRoute)} />
      <Route path=":id" {...createLazyRoute(module, m => m.retailersBordabordsDetailRoute)} />
      <Route path="new" {...createLazyRoute(module, m => m.createRetailersBordabordsRoute)} />
    </Route>

    <Route path="renters">
      <Route index {...createLazyRoute(module, m => m.rentersListRoute)} />
      <Route path=":id" {...createLazyRoute(module, m => m.renterDetailRoute)} />
      <Route path="new" {...createLazyRoute(module, m => m.createRenterRoute)} />
    </Route>

    <Route path="lafon">
      <Route index {...createLazyRoute(module, m => m.lafonListRoute)} />
      <Route path=":id" {...createLazyRoute(module, m => m.lafonDetailRoute)} />
      <Route path="new" {...createLazyRoute(module, m => m.createLafonRoute)} />
    </Route>

    <Route path="vehicles">
      <Route index {...createLazyRoute(module, m => m.vehiclesListRoute)} />
      <Route path=":id" {...createLazyRoute(module, m => m.vehicleDetailRoute)} />
      <Route path="new" {...createLazyRoute(module, m => m.createVehicleRoute)} />
    </Route>
  </Route>
);

export default retailersRoutes;
