import React from 'react';
import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

const rentersRoutes = (
  <Route path="renters">
    <Route index {...createLazyRoute(module, m => m.rentersListRoute)} />
    <Route path=":id" {...createLazyRoute(module, m => m.renterDetailRoute)} />
    <Route path="new" {...createLazyRoute(module, m => m.createRenterRoute)} />
  </Route>
);

export default rentersRoutes;
