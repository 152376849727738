import React, { FC, PropsWithChildren } from 'react';

import { Text } from '@mantine/core';

import logo from '@assets/logos/logo-large.svg';
import { renderNullable } from '@shared/utils/render';
import classes from './AuthLayout.module.css';

interface AuthLayoutProps {
  title?: string;
}

const AuthLayout: FC<PropsWithChildren<AuthLayoutProps>> = ({ title, children }) => {
  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <img src={logo} width={190} height={40} alt="Logo" />
      </div>

      <div className={classes.content}>
        {renderNullable(title, {
          onSome: title => (
            <Text ta="center" fz="xl" fw="bold" mb="md">
              {title}
            </Text>
          ),
        })}

        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
