import { AxiosError, HttpStatusCode } from 'axios';
import { Effect, Option, pipe } from 'effect';
import { throwResponse } from '@core/router';
import { RemoteData as RemoteDataType } from '@core/fp';
import { StringifiableRecord } from 'query-string';
import { Range as RangeType } from '@shared/modules/range';
import { Filter } from '@shared/modules/filter';

export namespace Http {
  export const TRANSACTION_ID_HEADER_KEY = 'X-Transaction-ID';

  export class Error<T = unknown> {
    readonly _tag = 'HttpError';

    constructor(
      public readonly status: HttpStatusCode,
      public readonly message?: string,
      public readonly url?: string,
      public readonly transactionId?: string,
      public readonly data?: T,
    ) {}

    log = () => {
      return Effect.logError(
        `[HttpError] status ${this.status}, url: ${this.url}, message: ${this.message}, transaction: ${this.transactionId}`,
      );
    };

    isDownError() {
      return this.status > 500;
    }

    toJson() {
      return {
        status: this.status,
        message: this.message,
        url: this.url,
        transactionId: this.transactionId,
        data: this.data,
      };
    }

    toResponse() {
      return new Response(this.message, { status: this.status });
    }

    throwResponse = () => {
      return throwResponse(this.toResponse());
    };

    fail = (): Effect.Effect<never, Error<T>, never> => {
      return Effect.fail(this);
    };

    static fromStatusCode<E = unknown>(status: HttpStatusCode, message?: string): Error<E> {
      return new Error(status, message);
    }

    static readonly default = Error.fromStatusCode(HttpStatusCode.InternalServerError);
    static readonly notFound = Error.fromStatusCode(HttpStatusCode.NotFound);

    static fromAxiosError<E = unknown>(error: AxiosError<E>): Error<E> {
      return pipe(
        Option.fromNullable(error.response),
        Option.match({
          onNone: () => new Error(HttpStatusCode.ServiceUnavailable),
          onSome: res => {
            const status = res.status === 0 ? HttpStatusCode.ServiceUnavailable : res.status;

            const message = pipe(
              Option.fromNullable(res.data as { message?: string }),
              Option.flatMapNullable(data => data.message),
              Option.map(message => message.toString()),
              Option.orElse(() => Option.fromNullable(error.message)),
              Option.getOrElse(() => 'unknown'),
            );

            const url = pipe(
              Option.fromNullable(res.config),
              Option.flatMapNullable(config => config.url),
              Option.getOrUndefined,
            );

            const transactionId = pipe(
              Option.fromNullable(error.config?.headers),
              Option.flatMapNullable(headers => headers.get(TRANSACTION_ID_HEADER_KEY)),
              Option.map(transactionId => `${transactionId}`),
              Option.getOrUndefined,
            );

            return new Error(status, message, url, transactionId, res.data);
          },
        }),
      );
    }
  }

  export type Effect<R = unknown, E = unknown> = Effect.Effect<R, Error<E>>;
  export type RemoteData<R = unknown, E = unknown> = RemoteDataType<Error<E>, R>;
  export type Range<
    R = unknown,
    F extends StringifiableRecord = {},
    S extends Filter.Sort = null,
    E = unknown,
  > = Effect<RangeType.Result<R, F, S>, E>;
}
