import React, { ReactNode } from 'react';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import { Option, Function, Boolean } from 'effect';
import { Http } from '@core/http';
import { RemoteData } from '@core/fp';

export function renderOptional<T, R extends ReactNode>(
  value: Option.Option<T>,
  {
    onSome = Function.constNull,
    onNone = Function.constNull,
  }: Partial<{
    onSome: (data: T) => R | null;
    onNone: () => R | null;
  }>,
): R | null {
  return Option.match(value, { onSome, onNone });
}

export function renderNullable<T, R extends ReactNode>(
  value: T | null | undefined,
  {
    onSome = Function.constNull,
    onNone = Function.constNull,
  }: Partial<{
    onSome: (data: T) => R | null;
    onNone: () => R | null;
  }>,
): R | null {
  return renderOptional(Option.fromNullable(value), { onSome, onNone });
}

export function renderConditional<R extends ReactNode>(
  value: boolean,
  {
    onTrue = Function.constNull,
    onFalse = Function.constNull,
  }: Partial<{
    onTrue: () => R | null;
    onFalse: () => R | null;
  }>,
): R | null {
  return Boolean.match(value, { onFalse, onTrue });
}

export function renderHttpRemoteData<E, A, R extends ReactNode>(
  value: Http.RemoteData<A, E>,
  {
    onPending = () => <DebouncedLineLoader />,
    onSuccess = Function.constNull,
    onFailure = error => <ErrorPage error={error} />,
  }: Partial<{
    onPending: () => R | ReactNode;
    onSuccess: (data: A) => R | null;
    onFailure: (error: Http.Error<E>) => R | ReactNode;
  }>,
): R | ReactNode {
  return RemoteData.match(value, { onPending, onSuccess, onFailure });
}
